// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';
import { QueryResponse } from '../organization-roles';

interface LanguagesReport {
	languageCode: string;
	languageName: string;
	translationPercent: number;
	validationPercent: number;
}

export interface translationDefault {
	id: number;
	entityName: string;
	entityId: number;
	field: string;
	languageCode: string;
	translatedValue: string;
	isValidate: boolean;
}
export interface translation {
	id: number;
	field: string;
	languageCode: string;
	translatedValue: string;
	isValidate: boolean;
}
export interface entityTranslation {
	entityId: number;
	translations: translation[];
}
export interface adminTranslation {
	entityName: string;
	entities: entityTranslation[];
}

interface LanguagesUsageResponse extends QueryResponse {
	data: {
		report: LanguagesReport[];
	};
	errors: any;
}
interface TranslationResponse extends QueryResponse {
	data: {
		translations: adminTranslation[];
	};
}
export const translationApi = createApi({
	reducerPath: 'translationApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	endpoints: (build) => ({
		getAdminTranslations: build.mutation<TranslationResponse, string[]>({
			query: (languageCodes: string[]) => ({
				url: `/api/translations`,
				params: { languageCodes: languageCodes.join(',') }
			})
		}),
		addOrUpdateTranslation: build.mutation<translationDefault, translationDefault>({
			query: (body: translationDefault) => ({
				url: `/api/translations/translate`,
				method: 'PUT',
				body: { translation: body }
			})
		}),
		setTranslationValidate: build.mutation({
			query: (body: { translationId: number; isValidate: boolean }) => ({
				url: `/api/translations/validate`,
				method: 'PUT',
				body
			})
		}),
		getUsageTranslation: build.query({
			query: () => `/api/translations/usage`
		}),
		getTipsTranslation: build.query({
			query: () => `/api/translations/getTips`
		}),
		getTaskItemsTranslation: build.query({
			query: () => `/api/translations/getTaskItems`
		}),
		getQuestsTranslation: build.query({
			query: () => `/api/translations/getQuests`
		}),
		getQuestionsTranslation: build.query({
			query: () => `/api/translations/getQuestions`
		}),
		getBlogTranslation: build.query({
			query: () => `/api/translations/getBlog`
		}),
		getSectionTranslation: build.query({
			query: () => `/api/translations/getSections`
		}),
		getReportTranslation: build.query<LanguagesUsageResponse, void>({
			query: () => `/api/translations/report`
		}),
		editAutoTranslation: build.mutation({
			query: () => ({
				url: `/api/translations/translate`,
				method: 'POST'
			})
		})
	})
});

export const {
	useEditAutoTranslationMutation,
	useGetBlogTranslationQuery,
	useGetSectionTranslationQuery,
	useGetQuestionsTranslationQuery,
	useGetQuestsTranslationQuery,
	useGetTaskItemsTranslationQuery,
	useGetTipsTranslationQuery,
	useGetUsageTranslationQuery,
	useLazyGetBlogTranslationQuery,
	useLazyGetQuestionsTranslationQuery,
	useLazyGetQuestsTranslationQuery,
	useLazyGetTaskItemsTranslationQuery,
	useLazyGetTipsTranslationQuery,
	useLazyGetUsageTranslationQuery,
	useLazyGetSectionTranslationQuery,
	useGetReportTranslationQuery,
	useGetAdminTranslationsMutation,
	useAddOrUpdateTranslationMutation,
	useSetTranslationValidateMutation
} = translationApi;
