// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';
import { QueryResponse } from '../organization-roles';

interface LanguagesUsage {
	title: string;
	languageCode: string;
	percent: number;
}
export interface Language {
	code: string;
	isDefault: boolean;
	isActive: boolean;
	label: string;
}

interface LanguagesUsageResponse extends QueryResponse {
	data: {
		languagesUsage: LanguagesUsage[];
	};
	errors: any;
}
interface LanguagesResponse extends QueryResponse {
	data: {
		languages: Language[];
	};
	errors: any;
}

export const languageApi = createApi({
	reducerPath: 'languageApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	endpoints: (build) => ({
		getLanguages: build.query<LanguagesResponse, void>({
			query: () => `/api/languages`
		}),
		getLanguagesUsage: build.query<LanguagesUsageResponse, void>({
			query: () => `/api/languages/getusage`
		})
	})
});

export const { useGetLanguagesQuery, useGetLanguagesUsageQuery } = languageApi;
