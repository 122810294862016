// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Permission } from '../admins';

// Externals
import { baseHeader } from '../base-header';

export interface QueryResponse {
	data: any;
	errors: any;
}

interface Role {
	key: string;
	name: string;
	description: string;
	scope: string;
	permissions: Permission[];
}

interface RolesResponse extends QueryResponse {
	data: {
		roles: Role[];
	};
	errors: any;
}

export const organizationRoleApi = createApi({
	reducerPath: 'organizationRoleApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	tagTypes: ['role'],
	endpoints: (build) => ({
		getRoles: build.query<RolesResponse, void>({
			query: () => '/api/roles/all',
			providesTags: ['role']
		}),
		getSingleRole: build.query({
			query: ({ roleId }: { roleId: number }) => `/api/roles/${roleId ?? -1}`,
			providesTags: ['role']
		}),
		changeRole: build.mutation({
			query: (body: { organizationId: number; userId: string; roleKey: string }) => ({
				url: `/api/roles/set`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['role']
		}),
		changeOwner: build.mutation({
			query: (body: { organizationId: number; oldOwnerId: string; newOwnerId: string }) => ({
				url: `/api/organizations/members/OwnerChange`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['role']
		})
	})
});

const hooks = organizationRoleApi;

export const useChangeOwnerMutation = hooks.useChangeOwnerMutation;
export const useChangeRoleMutation = hooks.useChangeRoleMutation;
export const useGetRolesQuery = hooks.useGetRolesQuery;

/**
 * @deprecated The `useGetSingleRoleQuery` hook will be obsolete in future versions.
 * RoleId is not more used in the application.
 * @throws Will be removed in future versions.
 */
export const useGetSingleRoleQuery = hooks.useGetSingleRoleQuery;
